



































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class MerchantDetail extends Vue {
  @Prop({ type: Array, required: true }) private data!: {
    name: string
    value: string
    type?: 'text' | 'image'
    class?: string
  }[]

  private valueClass(type: 'text' | 'image') {
    if (type === 'image') {
      return 'mb-2'
    }

    return 'w-3/12 hdpi-laptop:w-2/12'
  }
}
