import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  CreateMerchantRequest,
  UpdateMerchantRequest,
  UpdateMerchantAccountRequest,
  UpdateMerchantStatusRequest,
} from '@/data/payload/api/MerchantRequest'
import { MerchantPresenter } from '@/app/ui/presenters/MerchantPresenter'
import { Merchant, MerchantDetail, Merchants } from '@/domain/entities/Merchant'
import { Pagination } from '@/domain/entities/Pagination'
import { MERCHANT_PAGINATION } from '@/app/infrastructures/misc/Constants/pagination'

export interface MerchantState {
  isLoading: boolean
  paginationData: Pagination
  merchantData: Merchant[]
  merchantDetail: MerchantDetail
}

export interface DropDownInterface {
  value: number | string
  label: string
}

interface ProfileForm {
  name: string
  brand: DropDownInterface | null
  city: DropDownInterface | null
  district: DropDownInterface | null
  description: string
  address: string
  longitude: number | null
  latitude: number | null
  banner: Blob | File | null
}

interface BankForm {
  bankName: string
  accountName: string
  accountNumber: string
}

interface AccountForm {
  name: string
  email: string
  phone: string
}

interface CreateForm {
  profile: ProfileForm
  bank: BankForm
  account: AccountForm
}

interface EditForm {
  id: number
  profile: ProfileForm
  bank: BankForm
}

export interface paramsInterface {
  page?: number;
  perPage?: number;
  merchantName?: string | undefined;
  brandId?: string | undefined;
}

@Module({ namespaced: true, store, name: 'merchant', dynamic: true })
class MerchantController extends VuexModule implements MerchantState {
  private presenter: MerchantPresenter = container.resolve(MerchantPresenter)
  public isLoading = false
  public paginationData = new Pagination(1, MERCHANT_PAGINATION)
  public merchantData: Merchant[] = []
  public merchantDetail = new MerchantDetail()
  public statusUpdateMerchantStatus = ''
  public dataUpdateMerchantStatus = NaN
  public statusCreateMerchant = ''
  public statusUpdateMerchant = ''
  public statusUpdateMerchantAccount = ''

  @Action({ rawError: true })
  public createMerchant(form: CreateForm): void {
    this.setLoading(true)

    this.presenter
      .create(
        new CreateMerchantRequest(
          form.profile.name,
          <number>form.profile.brand?.value,
          form.profile.description,
          <string>form.profile.city?.value,
          <string>form.profile.district?.value,
          form.profile.address,
          <number>form.profile.latitude,
          <number>form.profile.longitude,
          <Blob>form.profile.banner,
          form.account.name,
          form.account.email,
          form.account.phone,
          form.bank.bankName,
          form.bank.accountName,
          form.bank.accountNumber
        )
      )
      .then(() => {
        this.setStatusCreateMerchant(EventBusConstants.CREATE_MERCHANT_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Merchant Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateMerchant(form: EditForm): void {
    this.setLoading(true)

    this.presenter
      .update(
        form.id,
        new UpdateMerchantRequest(
          form.profile.name,
          <number>form.profile.brand?.value,
          form.profile.description,
          <string>form.profile.city?.value,
          <string>form.profile.district?.value,
          form.profile.address,
          <number>form.profile.latitude,
          <number>form.profile.longitude,
          form.profile.banner,
          form.bank.bankName,
          form.bank.accountName,
          form.bank.accountNumber
        )
      )
      .then(() => {
        this.setStatusUpdateMerchant(EventBusConstants.UPDATE_MERCHANT_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Merchant Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateMerchantAccount(form: {
    id: number
    name: string
    email: string
    phone: string
  }): void {
    this.setLoading(true)

    this.presenter
      .updateAccount(
        form.id,
        new UpdateMerchantAccountRequest(form.name, form.email, form.phone)
      )
      .then(() => {
        this.setStatusUpdateMerchantAccount(EventBusConstants.UPDATE_MERCHANT_ACCOUNT_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Merchant Account Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateMerchantStatus(form: { id: number; isActive: boolean }): void {
    this.setLoading(true)

    this.presenter
      .updateStatus(form.id, new UpdateMerchantStatusRequest(form.isActive))
      .then(() => {
        this.setDataUpdateMerchantStatus(form.id)
        this.setStatusUpdateMerchantStatus(EventBusConstants.UPDATE_MERCHANT_STATUS_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: `${form.isActive ? 'Activate' : 'Deactive'} Merchant Failed`,
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getMerchantList(params: paramsInterface): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setMerchantData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Merchant Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getMerchantDetail(id: string): void {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(res => {
        this.setMerchantDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Merchant Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setMerchantData(merchants: Merchants): void {
    this.paginationData = <Pagination>merchants.pagination
    this.merchantData = <Merchant[]>merchants.data
  }

  @Mutation
  private setMerchantDetail(merchant: MerchantDetail): void {
    this.merchantDetail = merchant
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  public setStatusUpdateMerchantStatus(status: string): void {
    this.statusUpdateMerchantStatus = status
  }

  @Mutation
  public setDataUpdateMerchantStatus(status: number): void {
    this.dataUpdateMerchantStatus = status
  }

  @Mutation
  public setStatusCreateMerchant(status: string): void {
    this.statusCreateMerchant = status
  }

  @Mutation
  public setStatusUpdateMerchant(status: string): void {
    this.statusUpdateMerchant = status
  }

  @Mutation
  public setStatusUpdateMerchantAccount(status: string): void {
    this.statusUpdateMerchantAccount = status
  }
}

export default getModule(MerchantController)
