




















import { Component, Vue, Watch } from 'vue-property-decorator'
import MerchantDetail from '../../components/MerchantDetail/index.vue'
import BankCard from '@/app/ui/assets/bank_card.vue'
import controller from '@/app/ui/controllers/MerchantController'
import { MerchantDetail as MerchantDetailEntity } from '@/domain/entities/Merchant'

interface Detail {
  name: string
  value: string
  type?: string
}

@Component({
  components: {
    MerchantDetail,
    BankCard,
  },
})
export default class MerchantProfilePage extends Vue {
  controller = controller
  profile: Detail[] = []
  bank: Detail[] = []

  created(): void {
    const merchantDetail = controller.merchantDetail
    this.getProfile(merchantDetail)
    this.getBank(merchantDetail)
  }

  private getProfile(val: MerchantDetailEntity): void {
    this.profile = [
      {
        name: 'Merchant Name',
        value:  <string>val.name,
      },
      {
        name: 'Brand',
        value: <string>val.brand?.label,
      },
      {
        name: 'City',
        value: (<string>val.city?.label).replace(/\s\(([A-Z]{3}\))$/, ''),
      },
      {
        name: 'District',
        value: <string>val.district?.label,
      },
      {
        name: 'Description',
        value: <string>val.description
      },
      {
        name: 'Address',
        value: <string>val.address,
      },
      {
        name: 'Latitude',
        value: <string>val.latitude,
      },
      {
        name: 'Longitude',
        value: <string>val.longitude,
      },
      {
        name: 'Banner',
        value:
          <string>val.imageBannerUrl,
        type: 'image',
      },
    ]
  }

  private getBank(val: MerchantDetailEntity): void {
    this.bank = [
      {
        name: 'Bank',
        value: <string>val.bank,
      },
      {
        name: 'Nama',
        value: <string>val.bankAccountName,
      },
      {
        name: 'Nomor Rekening',
        value: <string>val.bankAccountNumber,
      },
    ]
  }

  @Watch('controller.merchantDetail')
  onMerchantDetailChanged(merchantDetail: MerchantDetailEntity): void {
    this.getProfile(merchantDetail)
    this.getBank(merchantDetail)
  }
}
