import {
  CreateMerchantRequestInterface,
  UpdateMerchantRequestInterface,
  UpdateMerchantStatusRequestInterface,
  UpdateMerchantAccountRequestInterface,
} from '@/data/payload/contracts/MerchantRequest'

export class CreateMerchantRequest implements CreateMerchantRequestInterface {
  public merchantName?: string
  public brandId?: number
  public description?: string
  public city?: string
  public district?: string
  public address?: string
  public latitude?: number
  public longitude?: number
  public merchantBannerImage?: Blob
  public accountName?: string
  public accountEmail?: string
  public accountPhoneNumber?: string
  public bankName?: string
  public bankAccountName?: string
  public bankAccountNumber?: string

  constructor(
    merchantName?: string,
    brandId?: number,
    description?: string,
    city?: string,
    district?: string,
    address?: string,
    latitude?: number,
    longitude?: number,
    merchantBannerImage?: Blob,
    accountName?: string,
    accountEmail?: string,
    accountPhoneNumber?: string,
    bankName?: string,
    bankAccountName?: string,
    bankAccountNumber?: string
  ) {
    this.merchantName = merchantName
    this.brandId = brandId
    this.description = description
    this.city = city
    this.district = district
    this.address = address
    this.latitude = latitude
    this.longitude = longitude
    this.merchantBannerImage = merchantBannerImage
    this.accountName = accountName
    this.accountEmail = accountEmail
    this.accountPhoneNumber = accountPhoneNumber
    this.bankName = bankName
    this.bankAccountName = bankAccountName
    this.bankAccountNumber = bankAccountNumber
  }

  public toPayload() {
    const data = new FormData()
    data.append('merchant_name', this.merchantName as string)
    data.append('brand_id', String(this.brandId))
    data.append('description', this.description as string)
    data.append('city', this.city as string)
    data.append('district', this.district as string)
    data.append('address', this.address as string)
    data.append('latitude', String(this.latitude))
    data.append('longitude', String(this.longitude))
    data.append('merchant_banner_image', this.merchantBannerImage as Blob)
    data.append('name', this.accountName as string)
    data.append('email', this.accountEmail as string)
    data.append('phone_number', this.accountPhoneNumber as string)
    data.append('bank_name', this.bankName as string)
    data.append('bank_account_name', this.bankAccountName as string)
    data.append('bank_account_number', this.bankAccountNumber as string)

    return data
  }
}

export class UpdateMerchantRequest implements UpdateMerchantRequestInterface {
  public merchantName?: string
  public brandId?: number
  public description?: string
  public city?: string
  public district?: string
  public address?: string
  public latitude?: number
  public longitude?: number
  public merchantBannerImage?: Blob | null
  public bankName?: string
  public bankAccountName?: string
  public bankAccountNumber?: string

  constructor(merchantName?: string,
    brandId?: number,
    description?: string,
    city?: string,
    district?: string,
    address?: string,
    latitude?: number,
    longitude?: number,
    merchantBannerImage?: Blob | null,
    bankName?: string,
    bankAccountName?: string,
    bankAccountNumber?: string) {
      this.merchantName = merchantName
      this.brandId = brandId
      this.description = description
      this.city = city
      this.district = district
      this.address = address
      this.latitude = latitude
      this.longitude = longitude
      this.merchantBannerImage = merchantBannerImage
      this.bankName = bankName
      this.bankAccountName = bankAccountName
      this.bankAccountNumber = bankAccountNumber
  }

  public toPayload() {
    const data = new FormData()
    data.append('merchant_name', this.merchantName as string)
    data.append('brand_id', String(this.brandId))
    data.append('description', this.description as string)
    data.append('city', this.city as string)
    data.append('district', this.district as string)
    data.append('address', this.address as string)
    data.append('latitude', String(this.latitude))
    data.append('longitude', String(this.longitude))
    if (this.merchantBannerImage) {
      data.append('merchant_banner_image', this.merchantBannerImage)
    }
    data.append('bank_name', this.bankName as string)
    data.append('bank_account_name', this.bankAccountName as string)
    data.append('bank_account_number', this.bankAccountNumber as string)

    return data
  }
}

export class UpdateMerchantAccountRequest
  implements UpdateMerchantAccountRequestInterface {
  public name?: string
  public email?: string
  public phone?: string

  constructor(name?: string, email?: string, phone?: string) {
    this.name = name
    this.email = email
    this.phone = phone
  }

  public toPayload() {
    const data = new FormData()
    data.append('account_name', this.name as string)
    data.append('email', this.email as string)
    data.append('phone_number', this.phone as string)

    return data
  }
}

export class UpdateMerchantStatusRequest
  implements UpdateMerchantStatusRequestInterface {
  public isActive?: boolean

  constructor(isActive?: boolean) {
    this.isActive = isActive
  }

  public toPayload() {
    const data = new FormData()
    data.append('status', this.isActive ? 'active' : 'suspend')

    return data
  }
}
